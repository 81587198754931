.adminarea1 > ul li {
  font-size: 14px;
}

.adminarea1 > ul li span {
  font-size: 14px;
}

.accessMangementList ul li {
  padding: 20px;
  background: gray;
  width: 25%;
  text-align: center;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.accessMangementList ul li:hover {
  background: rgb(58, 58, 58);
}
