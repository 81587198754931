.CapgateHeader {
  text-align: center;
  color: white;
  font-weight: bold;
  text-shadow: 5px 5px 5px #000000a6;
}

.Capgatepromo {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding-top: 3%;
}

.CapFormHeading {
  text-align: center;
  font-size: 26px;
  text-shadow: 3px 3px 5px #0c0c0c66;
  font-weight: 600;
  padding-top: 3%;
}

.CapMainArea form {
  border: 1px solid black;
  border-radius: 10px;
  padding: 12px;
  margin-top: 3%;
}

.CapMainArea form > .form-group {
  padding: 5px;
}

.CapMainArea form > .Capbutton {
  align-items: center;
  justify-content: center;
  display: flex;
}

.CapDropdownSelect {
  padding: 8px 12px;
  border-radius: 5px;
  background-color: #eeeeee;
  border: 1px solid #dddddd;
  width: 100%;
  font-size: 18px;
}

.CapDropdownSelect option {
  line-height: 20px;
}

.CapDropdownSelect:focus,
.CapDropdownSelect:hover {
  outline: none;
  border: 1px solid #bbbbbb;
}

.CapAdditionalMainSec {
  padding-left: 70px;
}

.CapAdditionalMainSec h4 {
  text-align: center;
  font-size: 26px;
  text-shadow: 3px 3px 5px #0c0c0c66;
  font-weight: 600;
  padding-top: 8%;
}

.capAdditionalSec {
  padding-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vl {
  border-left: 3px solid #878c873b;
  height: 500px;
  position: absolute;
  top: 17%;
  left: 5%;
  border-radius: 30%;
}

.capAdditionalSec button {
  padding: 5%;
  margin-top: 10%;
  width: 275px;
}

.CapInfo1 {
  font-size: 16px;
  font-weight: 550;
  text-align: justify;
  margin-bottom: 3%;
  font-style: italic;
  margin-top: 2%;
}

.CapInfo2 {
  display: flex;
  margin-top: 5%;
}

.CapInfo2 p {
  font-size: 18px;
  font-weight: bold;
}

.CapInfo2 ul {
  list-style-type: auto;
  padding-left: 6%;
}

.CapInfo2 ul li {
  padding-top: 1%;
  font-weight: 600;
}

.CapInfo3 p {
  font-size: 16px;
  font-weight: bold;
}
.CapInfo3 {
  margin-top: 3%;
  margin-left: 3%;
}
.CapInfo3 ul {
  list-style-type: auto;
  padding-left: 6%;
}

.CapInfo3 ul li {
  padding-top: 1%;
  font-size: 14px;
}

.hl {
  margin-top: 3%;
  width: 100%;
  border: 1px solid #69696938;
}

.infobaseDesign1 {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
}

.infobaseDesign2 {
  margin-left: 5%;
  margin-right: 5%;
}

.PriceLabel {
  text-align: center;
  font-weight: bold;
}
.ClassSchema1 {
  margin-top: 2%;
  margin-left: 5%;
  margin-right: 5%;
}

.SideBarContainer {
  background: white;
  padding: 10px;
  border-radius: 5px;
  padding-bottom: 30px;
  box-shadow: 3px 3px 5px black;
}

.SideBarContainer h4 {
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
}

.SideBarContainer ul li {
  padding-left: 20px;
  padding-bottom: 6px;
  font-size: 18px;
  color: #4d4a4a;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.SideBarContainer ul li:hover {
  color: #1d1d1d;
  cursor: pointer;
  font-weight: bolder;
}

.MainAreaContainer {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px black;
}

.MainAreaContainer h4 {
  padding: 20px;
  /* align-items: center; */
  text-align: center;
}

.Excel_form {
  padding: 20px;
  width: 85%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.SpecificClassHeader {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px black;
  margin-bottom: 15px;
}
