.sideBarContainer {
  background-image: -webkit-linear-gradient(
    108deg,
    rgb(59, 38, 219) 14%,
    rgb(123, 25, 203) 100%
  );
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.sideBarContainer h4 {
  color: white;
  text-align: center;
}

.sideBarContainer hr {
  border-color: rgb(50, 50, 50);
}

.sideBarContainer ul {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
}

.sideBarContainer ul li {
  padding-bottom: 18px;
  border-bottom: 1px solid #ffffff;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.sideBarContainer ul li a {
  color: #ffffff;
  font-weight: 600;
}

.sideBarContainer ul li:hover {
  background: #02202f;
  padding-left: 10px;
  border-radius: 10px;
}

.sideBarContainer ul li:hover a {
  color: white;
}
