.SideBarContainer {
  background: white;
  padding: 10px;
  border-radius: 5px;
  padding-bottom: 30px;
  box-shadow: 3px 3px 5px black;
}

.SideBarContainer h4 {
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
}

.SideBarContainer ul li {
  padding-left: 20px;
  padding-bottom: 6px;
  font-size: 18px;
  color: #4d4a4a;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.SideBarContainer ul li:hover {
  color: #1d1d1d;
  cursor: pointer;
  font-weight: bolder;
}

.MainAreaContainer {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px black;
}

.MainAreaContainer h4 {
  padding: 20px;
  /* align-items: center; */
  text-align: center;
}

.listInfoContainer {
  background: white;
  padding: 15px;
  border-radius: 10px;

  margin-top: 20px;
}

.GoBackContainer {
  display: flex;
  justify-content: space-between;
}

.GoBackContainer h4 {
  font-weight: bold;
  padding: 5px;
}

.ShowdataActionBtnContainer {
  display: flex;
  flex-direction: column;
}
.ShowdataActionBtnContainer button {
  width: 67px;
  font-size: 12px;
  padding: 8px;
  margin-bottom: 3px;
}

.contentSectionContainer {
  padding: 10px;
  background: white;
  margin-left: 10px;
  border-radius: 7px;
  width: inherit;
}

.contentSectionContainer .headerInfo {
  padding-left: 5px;
}
.contentSectionContainer .headerInfo h3 {
  display: flex;
  justify-content: space-between;
}

.contentSectionContainer .headerInfo span {
  cursor: pointer;
}

.contentSectionContainer .headerInfo p {
  padding-left: 10px;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 15px;
  transition: all 0.2s ease-in;
}

.tableContainer {
  padding: 2%;
}

.summaryContainer {
  padding-bottom: 20px;
  padding-left: 25px;
}

.summaryContainer h4 {
  font-weight: bold !important;
}

.disclaimerContainer {
  padding-bottom: 20px;
  padding-left: 25px;
  text-align: justify;
  padding-right: 10px;
}

.EditSectionContainer {
  background: white;
  padding: 3%;
  width: 80%;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 2px 2px 2px black;
}

.EditSectionContainer h6 {
  font-size: 20px;
  font-weight: 00;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.EditSectionContainer h6 button {
  line-height: 17px;
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
