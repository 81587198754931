.ModalFooterSec {
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 5px;
}

.ModalFooterSec button {
  padding-left: 3%;
  margin: 1%;
}
